import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un capell de fins a 6 cm de diàmetre, que passa de globós a més o manco pla. Aquest és de color groc més o manco brut amb unes esquames petites un poc més fosques, que desapareixen en l’edat. És viscós en temps humit. El marge del capell és excedent i apendiculat. Les làmines són amples, adnades, nombroses, de color groguenc a marró olivaci, amb moltes lamel·les. El peu és cilíndric, de 3-7 x 0,5-1 cm, de color més pàl·lid que el capell, amb una zona anular fugaç per davall la qual presenten unes petites esquames de color bru. La base freqüentment es troba tacada d’un color bru vermellós.
Les espores són de color rovell en massa, el·líptiques, de 6-7 x 3-4 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      